import axios from 'axios'
import Cookies from 'universal-cookie'

export const ApiUrlBase = process.env.REACT_APP_API_URL
// export const ApiUrlBase = 'http://ns-dev.ctaspro.com:8080'

const cookies = new Cookies()

export const axiosCtasBE = axios.create({
    baseURL: ApiUrlBase,
    timeout: 20000,
    withCredentials: true,
})

const delay = async (ms: number) => {
    return new Promise((resolve) => {
        setTimeout(() => resolve('Done'), ms)
    })
}

const redirectToLogin = (appId: string) => {
    if (appId) {
        window.location.href = `${process.env.REACT_APP_COGNITO_LOGIN}%2F${appId.toUpperCase()}`
    }
}

export const getValidToken = (appId: string) => {
    const authToken = cookies.get('lock')
    if (!authToken) {
        redirectToLogin(appId);
        return ''
    }

    // I can open the token, check expire and refresh it beforehand
    return authToken
}

export const apiGetCall = async (appId: string, url: string) => {
    const validToken = getValidToken(appId);
    if (validToken) {
            try {
                const response = await axios.get(`${ApiUrlBase}/${url}`,{
                    headers: {
                        Authorization: validToken,
                        "http-equiv": "Content-Security-Policy",
                        "content": "upgrade-insecure-requests"
                    }
                })
                return response
            } catch (err: any) {
                if (err.response?.status === 401) {
                    console.log("401 => Redirecting to Login")
                    cookies.remove('lock')
                    redirectToLogin(appId)
                } else {
                    throw new Error(`Error en los Datos - ${url}`);
                }
            }
    } else {
        cookies.remove('lock')
        redirectToLogin(appId)
    }
}

export const apiPostCall = async (appId: string, url: string, params: any) => {
    const validToken = getValidToken(appId);
    if (validToken) {
        try {
            const response = await axios.post(`${ApiUrlBase}/${url}`, params,{
                headers: {
                    Authorization: validToken
                }
            })
            return response
        } catch (error: any) {
            if (error.response?.status === 401) {
                console.log("401 => Redirecting to Login")
                cookies.remove('lock')
                redirectToLogin(appId)
            } else {
                console.error(error)
                throw new Error(error)
            }
        }
    }
}

export const apiDelCall = async (appId: string, url: string) => {
    const validToken = getValidToken(appId);
    if (validToken) {
        try {
            const response = await axios.delete(`${ApiUrlBase}/${url}`,{
                headers: {
                    Authorization: validToken
                }
            })
            return response
        } catch (error: any) {
            if (error.response?.status === 401) {
                console.log("401 => Redirecting to Login")
                cookies.remove('lock')
                redirectToLogin(appId)
            }
        }
    }
}
