import { IFieldControl } from '../Edit/Edit.component';

export const CheckInput = (props: IFieldControl) => {
    const { onChangeHandler, value } = props
    

    const handleOnChange = (event: any) => {
        onChangeHandler(props.field, event.target.checked)
    }

    return (
        <input type='checkbox'
            checked={value}
            onChange={handleOnChange}
        />
    )
}