import Cookies from 'universal-cookie'
import NavbarMenu from './NavbarMenu'
import { CSSProperties } from 'react';
import Notifications from './Notification.component';

const HeaderStyle: CSSProperties = {
    display: 'flex', 
    justifyContent: 'space-between',
    margin: '10px 25px',
    alignItems: 'center',
    height: '50px'
}

const MenuStyle: CSSProperties = {
    display: 'flex', 
    margin: '10px 25px',
    alignItems: 'center',
    height: '50px'
}

const clientId = '55dboarcaps4sj4k3our2unadn'
const logoutCallbackUrl = encodeURIComponent(process.env.REACT_APP_API_URL || '')
const logoutUrl = `https://ctas-${process.env.REACT_APP_NAME === 'local' ? 'dev' : process.env.REACT_APP_NAME}.auth.us-east-1.amazoncognito.com/logout?client_id=${clientId}&response_type=code&redirect_uri=${logoutCallbackUrl}`

const logOut = () => {
    const cookies = new Cookies()
    cookies.remove('lock')
    window.location.href=logoutUrl
}

export const Header = () => {
    return (
        <>
            <div style={HeaderStyle}>
                <div style={{flexGrow: 1}}>
                    Zeta Software
                </div>
                <div style={{flexGrow: 1}}>
                    <Notifications />
                </div>
                <div style={{flexGrow: 1, textAlign: "right"}}>
                    Perfil y Configs
                    <a onClick={logOut}>Logout</a>
                </div>
            </div>
            <div style={{flexGrow: 3, textAlign: "center"}}>
                <NavbarMenu/>
            </div>
        </>
    )
    
}
