import { ThunkDispatch } from '@reduxjs/toolkit'
import { CSSProperties } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteData, saveData } from '../../../store/slices/data.slice'
import { removeTab, resetEditValues, updateEditValues } from '../../../store/slices/tabs.slice'
import { styleComponentContainer, styledSectionBox } from '../../styles/shared-components.style'
import { TabParams,EditField, FieldType, TabType } from '../../models/tabs.model'
import { CommandButton } from '../Buttons/ActionButton.component'
import { TextInput } from '../Controls/Input.control'
import { DateInput } from '../Controls/date.control'
import { SearchBoxInput } from '../Controls/search-box.control'
import { CheckInput } from '../Controls/check.control'
import { NotificationType, addNotification } from '../../../store/slices/notifications.slice'
import { AppDefinition } from '../../../store/slices/app.slice'
import { MoneyInput } from '../Controls/Money.control '
import { IntegerInput } from '../Controls/Integer.control'

const styledEditDataPanel: CSSProperties = {
    ...styledSectionBox,
    width: '80%'
}
const styledEditButtonsPanel: CSSProperties = {
    ...styledSectionBox,
    width: '20%'
}

export interface IFieldControl extends EditField {
    onChangeHandler: Function, 
}

const FieldControl = (props: IFieldControl) => {
    if (props.type === FieldType.SIMPLE_TEXT) {
        return TextInput({field: props.field, value: props.value, onChangeHandler: props.onChangeHandler})
    } else if (props.type === FieldType.DATE) {
        return DateInput({field: props.field, value: props.value, onChangeHandler: props.onChangeHandler, config: props.config})
    } else if (props.type === FieldType.MONEY) {
        return MoneyInput({field: props.field, value: props.value, onChangeHandler: props.onChangeHandler, config: props.config})
    } else if (props.type === FieldType.INTEGER) {
        return IntegerInput({field: props.field, value: props.value, onChangeHandler: props.onChangeHandler, config: props.config})
    } else if (props.type === FieldType.SEARCHBOX) {
        return SearchBoxInput(props)
    } else if (props.type === FieldType.CHECK) {
        return CheckInput(props)
    } else {
        return (<p>No Valid Ctrl Type</p>)
    }
}

// Export this to use everywhere?
const Field = ({editField, onChangeHandler}:{
    editField: EditField,
    onChangeHandler: Function
}) => {
    return (
        <div style={{display: 'flex', margin: '12px'}}>
            <br />
            <label style={{width: '30%', textAlign: 'right', padding: '8px'}}>
                {editField.label}
                {editField.required && 
                    <span style={{color: 'red'}}>*</span>
                }
            </label>
            {FieldControl({...editField, onChangeHandler})}
        </div>
    )
}

const EditButtons = ({editTab}: {editTab: TabParams}) => {
    const { appDefinition } = useSelector((state: {app: {appDefinition: AppDefinition}}) => state.app)
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>()

    const deleteHandler = () => {
        // TODO: Add Confirmation
        const id = editTab.editFields?.find(f => f.field === editTab.idField)?.value
        if (id) {
            dispatch(deleteData({
                dataType: editTab.dataId,
                id
            }))
            dispatch(removeTab(editTab.id))
        }
    }

    const closeHandler = () => {
        // TODO: Check if data is saved, and warn
        dispatch(removeTab(editTab.id))
    }

    const saveHandler = (preventClose: boolean, reset: boolean) => {
        let bodyParams = {}

        const requiredButEmptyFields = editTab.editFields?.filter(ef => ef.required && ef.value === undefined)
        if (requiredButEmptyFields && requiredButEmptyFields.length > 0) {
            dispatch(addNotification({title: 'Por favor, completar todos los datos Requeridos', type: NotificationType.ERROR}))
            return 
        }

        let isInsert = true
        editTab.editFields?.forEach(f => {
            if (f.value !== undefined) {
                bodyParams = {...bodyParams, [f.field]: f.value}
                if (f.field === editTab.idField) {
                    isInsert = false
                }
            }
        })

        if (isInsert && editTab.insertTransformer) {
            bodyParams = appDefinition?.transformers?.find(t => t.name === editTab.insertTransformer)?.function(bodyParams)
        } else if (!isInsert && editTab.updateTransformer) {
            bodyParams = appDefinition?.transformers?.find(t => t.name === editTab.updateTransformer)?.function(bodyParams)
        }

        dispatch(saveData({
            dataType: editTab.dataId,
            values: bodyParams
        }))
        
        if (reset) {
            console.log("### RESETING TAB", editTab.id)
            dispatch(resetEditValues({tabId: editTab.id}))
        }
        if (!preventClose) {
            // TODO: Make closing optional!
            dispatch(removeTab(editTab.id))
        }
    }

    return (
        <div style={{textAlign: 'center', margin: '10px'}}>
            <CommandButton onClickHandler={saveHandler}>Guardar</CommandButton>
            <CommandButton onClickHandler={() => saveHandler(true, true)}>Guardar +</CommandButton>
            <CommandButton onClickHandler={deleteHandler}>Eliminar</CommandButton>
            <CommandButton onClickHandler={closeHandler}>Cerrar</CommandButton>
        </div>
    )
}

export const Edit = ({editParams}: {editParams: TabParams}) => {
    const dispatch = useDispatch()

    // This updates the field value in state
    const fieldEditChangeHandler = (field: string, value: any) => {
        const newValues: EditField[] = editParams.editFields?.map(f => {
            return {
                ...f,
                value: f.field === field ? value : f.value
            }
        }) || []
        dispatch(updateEditValues({tabId: editParams.id, values: newValues}))
    }

    return (
        <div style={styleComponentContainer}>
            <div style={styledEditDataPanel}>
                {editParams.editFields?.filter(f => 
                    f.show
                ).map(f => 
                    <Field 
                        key={f.field}
                        editField={f}
                        onChangeHandler={fieldEditChangeHandler}
                    />
                )}
            </div>
            <div style={styledEditButtonsPanel}>
                <EditButtons editTab={editParams}/>
            </div>
        </div>
    )
}