import { BrowseFilter, FieldType, TabParams, Title } from '../../models/tabs.model'
import { StepType } from '../../models/report.model'
import filter from '../Filter/filter.helper'
import { calcDefaultDateValue } from '../Controls/date.helper'

export const orderList = (list: any[], groupBy: string[]) => {
    let toOrder = JSON.parse(JSON.stringify(list))
    
    if (toOrder.length <=1) {
        return toOrder
    }

    return list.sort((a: any, b: any) => {
        let inOrder = true;
        groupBy.forEach(g => {
            if (a[g] > b[g]) inOrder = false
        })
        return inOrder ? 1 : -1
    })
}

export const valuesToTab = (values: any, tab: TabParams) => {
    tab.editFields?.forEach(f => {
        if (values[f.field]) {
            f.value = values[f.field]
        }
    })
}

const filterSource = (source: any[], stepFilters: string[], params: BrowseFilter[]) => {
    const paramsToFilter = params.filter(p => stepFilters?.includes(p.filterId))
    return filter(source, paramsToFilter, true)
}

const validateFormulaParts = (part1: string, part2: string, operation: string) => {
    // TO DO: IN THE FUTURE VALIDATE IF EVERYTHING IS OK
    return true
}

const calcFormula = (formula: string, currentRow: any, previousRow: any) => {
    // TO DO: Support multiple operations in the same row
    const formulaParts = formula.split('###')
    const part1 = formulaParts[0]
    const operation = formulaParts[1]
    const part2 = formulaParts[2]

    if (!validateFormulaParts(part1, part2, operation)) {
        return undefined
    }
    let value1
    if (isNaN(Number(part1))) {
        if (part1.includes('@@@')) {
            return 0 // For now...
        } else {
            const part1subparts = part1.split('.')
            if (part1subparts[0] === 'CURRENT') {
                value1 = currentRow[part1subparts[1]]
            } else if (part1subparts[0] === 'PREV') {
                value1 = previousRow ? previousRow[part1subparts[1]] : 0 // Can be the first row
            } else {
                return undefined
            }
        }
    } else {
        value1 = Number(part1)
    }
    let value2
    if (isNaN(Number(part2))) {
        if (part2.includes('@@@')) {

        } else {
            const part2subparts = part2.split('.')
            if (part2subparts[0] === 'CURRENT') {
                value2 = currentRow[part2subparts[1]]
            } else if (part2subparts[0] === 'PREV') {
                value2 = previousRow ? previousRow[part2subparts[1]] : 0 // Can be the first row
            } else {
                return undefined
            }
        }
    } else {
        value2 = Number(part2)
    }

    if (operation === 'PLUS') {
        return value1 + value2
    }
}

const calcSource = (source: any[], stepTitles: Title[], addReportLineType?: string) => {
    let prevRow: any 
    let newSource: any[] = []

    source.forEach(currentRow => {
        let newRow: any = {}
        stepTitles.forEach(t => {
            if (!t.operation || !t.operation.includes('###')) {
                newRow[t.field] = currentRow[t.field]
            } else {
                newRow[t.field] = calcFormula(t.operation, currentRow, prevRow)
            }
        })
        newSource.push({...newRow, reportTypeLine: addReportLineType})
        prevRow = newRow
    })
    return newSource
}

const groupBeforeCalcSource = (source: any[], stepTitles: Title[], titles: Title[]) => {
    const groupField = stepTitles.find(t => t.operation === 'GROUP')?.field;
    if (groupField) {
        const groups = Array.from(new Set(source.map(row => row[groupField])))
        const calculatedByGroups: any[] = []
        groups.forEach(g => {
            calculatedByGroups.push({
                [groupField]: g,
                field: groupField, 
                value: g, 
                rowType: 'GROUP_HEADER', 
                title: stepTitles.find(t => t.field === groupField)})
            calculatedByGroups.push({rowType: 'TITLES', [groupField]: g, titles})
            const calculatedGroup = calcSource(source.filter(r => r[groupField] === g), stepTitles, 'row')
            if (calculatedGroup.length) {
                calculatedByGroups.push(...calculatedGroup)
            }
        })
        return calculatedByGroups
    } else {
        return calcSource(source, stepTitles)
    }
}

const sumSource = (source: any[], stepTitles: Title[], params: BrowseFilter[]) => {
    
    const getSubtotalId = (stepTitle: Title) => {
        if (stepTitle.config?.valueField) {
            return stepTitle.config?.valueField
        } 
        return `${stepTitle.operation}${stepTitle.operation ? '.' : ''}${stepTitle.field}`
    }

    const getNewGroupFields = (data: any) => {
        const newGroup: any = {}
        stepTitles.filter(t => t.operation === 'GROUP').forEach(t => {
            newGroup[t.field] = data[t.field]
        })
        stepTitles.filter(t => t.operation === 'USE_DEF_VALUE').forEach(t => {
            let defaultValue
            if (t.fieldType == FieldType.DATE) {
                defaultValue = calcDefaultDateValue(t.config?.defaultValue)
            } else {
                defaultValue = t.config?.defaultValue
            }
            newGroup[t.field] = defaultValue
        })
        stepTitles.filter(t => t.operation === 'USE_PARAM_VALUE').forEach(t => {
            newGroup[t.field] = params.find(p => p.filterId === t.config?.valueField)?.value
        })
        stepTitles.filter(t => !t.operation).forEach(t => {
            newGroup[t.field] = data[t.field]
        })
        return newGroup
    }

    let subTotals: any = {}

    const groupBy = stepTitles?.find(t => t.operation === 'GROUP')?.field || ''
    source.forEach(data => {
        // POR AHORA SOLO AGRUPA UNA COL, PERO TAL VEZ SE PUEDE ADAPTAR A MULTIPLES O USAR MULTIPLE STEPS?
        const group = subTotals[data[groupBy]] || getNewGroupFields(data)

        stepTitles?.filter(t => t.operation === 'SUM').forEach(op => {
            const balance = group[getSubtotalId(op)] || 0
            group[getSubtotalId(op)] = balance + data[op.field]
        })

        subTotals[data[groupBy]] = group
    })

    return Object.values(subTotals)
}

const unionSources = (allSources: any, sourcesToUnion: string[]) => {
    let united: any[] = []

    sourcesToUnion.forEach(stu => {
        united.push(...(allSources[stu]))
    })

    return united
}

export const getReport = (fullList: any[], report: TabParams) => {
    const sources: any = {} 

    let lastSource = '';
    (report.reportSteps || []).forEach(reportStep => {
        const stepSource = reportStep.stepSource ? sources[reportStep.stepSource] : fullList
        let processed
        if (reportStep.stepType === StepType.FILTER) {
            processed = filterSource(stepSource, reportStep.stepFilters || [], report.parameters || [])
        } else if (reportStep.stepType === StepType.SUM) {
            processed = sumSource(stepSource, reportStep.stepTitles, report.parameters || [])
        } else if (reportStep.stepType === StepType.UNION) {
            processed = unionSources(sources, reportStep.stepSources)
        } else if (reportStep.stepType === StepType.CALC) {
            processed = groupBeforeCalcSource(stepSource, reportStep.stepTitles, report.browseTitles || [])
        }
        sources[reportStep.stepDestination] = processed
        lastSource = reportStep.stepDestination
    })

    if (report.reportRenderType === 'GROUPS') {

    }
    // console.log("### SOURCES", sources)
    return sources[lastSource] || []
}
