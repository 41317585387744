import { CSSProperties } from 'react'
import { TabParams } from '../../models/tabs.model'
import { useDispatch } from 'react-redux'
import { ThunkDispatch } from '@reduxjs/toolkit'
import { updatePaginationValues } from '../../../store/slices/tabs.slice'

const styledPaginator: CSSProperties = {
    display: 'flex',
    justifyContent: 'right',
}


interface PaginatorProps {
    rowsPerPage: number,
    currentPage: number,
    totalRows: number
}
export const Paginator = ({tab}: {tab: TabParams}) => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
    const { pagination } = tab
    const {totalRows, rowsPerPage} = pagination!

    const calcLastPage = () => {
        return Math.ceil((pagination!.totalRows || 0) / pagination!.rowsPerPage) 
    }
    
    const handleClickNext = () => {
        dispatch(updatePaginationValues({tabId: tab.id, values: {...tab.pagination!, currentPage: tab.pagination!.currentPage + 1}}))
    }
    
    const handleClickPrev = () => {
        dispatch(updatePaginationValues({tabId: tab.id, values: {...tab.pagination!, currentPage: tab.pagination!.currentPage - 1}}))
    }

    return (
        <div style={styledPaginator}>
            <button disabled={pagination!.currentPage === 1} onClick={() => handleClickPrev()}>Anterior</button>
            <span style={{padding: '0 10px'}}>{` Página ${pagination!.currentPage} de ${calcLastPage()}`}</span>
            <button disabled={calcLastPage() === pagination!.currentPage} onClick={() => handleClickNext()}>Siguiente</button>
        </div>
    )
}