import { useEffect, useState } from 'react';
import { Header } from "./app/Shared/Header/Header.component";
import { TabsComponent } from './app/Shared/Tabs/Tabs.component';
import { Login } from './app/auth/Authorizer';
import { BrowserRouter, Route, Routes,  } from 'react-router-dom';
import { apiGetCall } from './app/data/api.data';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { setAppId } from './store/slices/data.slice';
import { selectApp } from './store/slices/app.slice';


const AccountsApp = ({appId}: {appId: string}) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
  const [authorized, setAuthorized] = useState(false)

  useEffect(() => {
    dispatch(setAppId(appId))
    dispatch(selectApp(appId))

    let urlParams = new URLSearchParams(window.location.search);
    
    if (!urlParams.get('code')) {
      apiGetCall(appId, 'verify').then((response) => {
        if (response) {
          setAuthorized(true)
        }
      })
    }
  }, [])

  return (
    <div className="App">
      {authorized && 
        <>
          <Header />
          <TabsComponent />
        </>
      }
    </div>
  )
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/app/accounts' element={<AccountsApp appId='CTAS' />} />
        <Route path='/app/cave' element={<AccountsApp appId='CAVE' />} />
        <Route path='/login/:appId' element={<Login />} />
        <Route path='/' element={`<h1>Landing Page! X1-${process.env.REACT_APP_NAME}</h1>`} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
