import { ClearButton } from './ClearButton.component'

interface TextInputProps {
    field: string,
    value: string,
    onChangeHandler: Function,
    showClearButton?: boolean
}
export const TextInput = ({field, value, onChangeHandler, showClearButton}: TextInputProps) => {
    const handleOnChange = (event: any) => {
        onChangeHandler(field, event.target.value)
    }

    return (
        <>
            <input 
                style={{width: '70%'}}
                value={value}
                onChange={handleOnChange}
            />
            {showClearButton && 
                <ClearButton action={() => handleOnChange({target: {value: undefined}})}/>
            }
        </>
    )
}
