import { CSSProperties, useState } from 'react';
import { colorsActionButton, colorsCommandButton } from '../../styles/colors';
import { IActionMenu } from './ActionMenu.component';
import { MENU_ACTION_TYPE } from '../../models/menu.model';
import { ActionButton } from '../Buttons/ActionButton.component';
import { useSelector } from 'react-redux';
import { AppDefinition } from '../../../store/slices/app.slice';

const MenuMainOptionStyle: CSSProperties = {
    position: 'relative',
    padding: '8px 16px',
    margin: '0 20px 0 0',
    maxWidth: '150px',
    cursor: 'pointer'
}

const NavBarMainOption = ({menu}: {menu: IActionMenu}) => {
    const [isHover, setIsHover] = useState(false)

    const setStyling = (selected: boolean): CSSProperties => {
        if (selected) {
            return {
                ...MenuMainOptionStyle,
                backgroundColor: colorsActionButton.NormalBackground
            }
        }
        return MenuMainOptionStyle
    }

    return (
        <div
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            style={setStyling(isHover)}>
                {menu.title}
                {(menu.actionType === MENU_ACTION_TYPE.SUBMENU && isHover) && 
                    <SubMenu options={menu.subMenu || []} closeMenu={()=> setIsHover(false)}/>
                }
        </div>
    )
}

const SubMenuStyle: CSSProperties = {
    position: 'absolute',
    left: 0,
    top: '100%',
    padding: '0',
    margin: '0 10px',
}

const SubMenu = ({options, closeMenu}: {options: IActionMenu[], closeMenu: Function}) => {
    return (
        <ul style={SubMenuStyle}>
            {
                options.map((o, index) => 
                    <ActionButton 
                        actionMenu={o}
                        key={index}
                        closeMenu={closeMenu}
                    >
                        {o.title}
                    </ActionButton>
                )
            }
        </ul>
    )
}

const MenuStyle: CSSProperties = {
    display: 'flex', 
    margin: '10px 25px 0px 10px',
    alignItems: 'center',
    height: '50px'
}

const NavbarMenu = () => {

    const { appDefinition } = useSelector((state: any) => state.app as {appDefinition: AppDefinition})

    return (
        <div style={MenuStyle}>
            {
                appDefinition?.menu && appDefinition.menu.map(m => <NavBarMainOption key={m.title} menu={m} />)
            }
        </div>
    )
}

export default NavbarMenu