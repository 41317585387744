import { BrowseFilter, BrowserFilterTypes, FieldType } from '../../models/tabs.model'
import { transformCompleteDateToJustDate } from '../Controls/date.helper'

export const filter = (fullList: any[], filters: BrowseFilter[], useValues?: boolean) => {
    let filteredList = fullList
    filters
    .filter(filter => (useValues && filter.value !== undefined) || ( !useValues && filter.applied !== undefined))
    .forEach((filter) => {
        filteredList = filteredList.filter(fl => {
            if (filter.filterType === BrowserFilterTypes.MATCH) {
                if (filter.type === FieldType.DATE) {
                    return transformCompleteDateToJustDate(fl[filter.field]) === (useValues ? filter.value : filter.applied)
                }
                return fl[filter.field] === useValues ? filter.value : filter.applied
            } else if (filter.filterType === BrowserFilterTypes.TEXT_SEARCH) {
                if (filter.filterConfig?.caseSensitive) {
                    return fl[filter.field].includes(useValues ? filter.value : filter.applied)
                } else {
                    return fl[filter.field].toLowerCase().includes((useValues ? filter.value : filter.applied).toLowerCase())
                }
            } else if (filter.filterType === BrowserFilterTypes.MIN) {
                if (filter.type === FieldType.INTEGER || filter.type === FieldType.MONEY) {
                    if (filter.filterConfig?.excludeEquals) {
                        return (useValues ? filter.value : filter.applied) < fl[filter.field]
                    } else {
                        return (useValues ? filter.value : filter.applied) <= fl[filter.field]
                    }
                } else if (filter.type === FieldType.DATE) {
                    if (filter.filterConfig?.excludeEquals) {
                        return (useValues ? filter.value : filter.applied) < transformCompleteDateToJustDate(fl[filter.field]) 
                    } else {
                        return (useValues ? filter.value : filter.applied) <= transformCompleteDateToJustDate(fl[filter.field]) 
                    }
                }
            } else if (filter.filterType === BrowserFilterTypes.MAX) {
                if (filter.type === FieldType.INTEGER || filter.type === FieldType.MONEY) {
                    if (filter.filterConfig?.excludeEquals) {
                        return (useValues ? filter.value : filter.applied) > fl[filter.field]
                    } else {
                        return (useValues ? filter.value : filter.applied) >= fl[filter.field]
                    }
                } else if (filter.type === FieldType.DATE) {
                    if (filter.filterConfig?.excludeEquals) {
                        return (useValues ? filter.value : filter.applied) > transformCompleteDateToJustDate(fl[filter.field]) 
                    } else {
                        return (useValues ? filter.value : filter.applied) >= transformCompleteDateToJustDate(fl[filter.field]) 
                    }
                }
            }

            return true
        })
    })

    return filteredList
}

export default filter