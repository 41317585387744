import { useEffect, useState } from 'react';
import { IFieldControl } from '../Edit/Edit.component'
import { Title } from '../../models/tabs.model'
import Select, { StylesConfig } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../../../store/slices/data.slice';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { ClearButton } from './ClearButton.component';
import { AppDefinition } from '../../../store/slices/app.slice';

const composeLabel = (fields: string[], option: any) => {
    let label = ''
    fields.forEach(f => {
        if (label !== '') {
            label = label + ' '
        }
        label = label + option[f]
    })

    return label
}

export const SearchBoxLabel = (value: any, title: Title) => {
    // Habría que optimizar esto sacando lo afuera del loop y llamando una sola vez?
    const { fullList } = useSelector((state: any) => state.data.data[title.config?.source?.toLowerCase() || ''])

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
    useEffect(() => {
        if (!fullList?.length) {
            dispatch(fetchData({dataType: title.config?.source || ''}))
        }
    }, [])

    const calcShowValue = () => {
        if (fullList?.length && title.config?.showFields && title.config?.showFields?.length > 0) {
            const record = fullList.find((fl: any) => fl[title.config?.valueField || ''] === value)
            if (!record) {
                return ''
            } else {
                const separator = title.config?.separator || ' '
                return title.config.showFields.reduce((toShow: string, current: string) => `${toShow}${toShow ? separator : ''}${record[current]}`, '')
            }
        } else {
            return ''
        }
    }

    return (
        <span>
            {
                calcShowValue()
            }
        </span>
    )

}

interface SearchBoxInputProps extends IFieldControl {
    showClearButton?: boolean
}

export const SearchBoxInput = (props: SearchBoxInputProps) => {
    const { onChangeHandler, config } = props
    const [ options, setOptions ] = useState<{label: string, value: string}[]>([])
    const { fullList } = useSelector((state: any) => state.data.data[config?.source?.toLowerCase() || ''])
    const { appDefinition } = useSelector((state: {app: {appDefinition: AppDefinition}}) => state.app)
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>()
    useEffect(() => {
        dispatch(fetchData({dataType: config?.source || ''}))
    }, [])

    const handleOnChange = (newValue: any) => {
        onChangeHandler(props.field, newValue.value)
    }

    useEffect(() => {
        const formattedOptions = fullList
            .filter((fl:any) => {
                return config?.sourceFilter ? appDefinition.filters?.find(f => f.name === config?.sourceFilter)?.function(fl) : true
            })
            .map((o: any) => {
            return {
                value: o[config?.valueField || ''],
                label: composeLabel(config?.showFields || [], o)
            }
        })
        setOptions(formattedOptions)
    }, [fullList])

    const selectStyles: StylesConfig = {
        container: (styles) => ({...styles, minWidth: '60%'})
    }

    return (
        <>
            <Select 
                value={options.filter(o => o.value === props.value)}
                options={options} 
                styles={selectStyles}
                onChange={handleOnChange}
                placeholder={config?.placeholder || 'Seleccione una opción'}
            />
            {props.showClearButton && 
                <ClearButton action={() => onChangeHandler(props.field, undefined)}/>
            }
        </>
    )
}