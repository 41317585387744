import { CSSProperties } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NotificationModel, NotificationType, removeLastNotification } from '../../../store/slices/notifications.slice'

const NotificationStyle: CSSProperties = {
    padding: '10px 10px',
    color: 'white',
    textAlign: 'center',
}

const Notifications = () => {
    const dispatch = useDispatch()
    const { notifications } = useSelector((state: {notifications: {notifications: NotificationModel[]}}) => state.notifications)

    const handleRemoveLastNotification = () => {
        dispatch(removeLastNotification())
    }

    return (
        <> 
            {notifications?.length ?
                <div style={{
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    backgroundColor: notifications[0].type === NotificationType.ERROR ? 'red' : 'green',
                    ...NotificationStyle, 
                }}>
                    <div>O</div>
                    <div>
                        {notifications[0].title}
                    </div>
                    <div style={{cursor: 'pointer'}} onClick={handleRemoveLastNotification}>X</div>
                </div>
            : null}
        </>
    )
}

export default Notifications