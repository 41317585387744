const addZeroToCompleteTwo = (original: number) => {
    return `0${original}`.slice(-2)
}

const getDaysInMonth = (month: number, year: number) => {
    return month===2 ? year & 3 || !(year%25) && year & 15 ? 28 : 29 : 30 + (month+(month>>3)&1);
}

export const getCurrentMonthLastDay = () => {
    const today = new Date();
    const month = today.getMonth() + 1;
    const lastDay = getDaysInMonth(month, today.getFullYear());

    return `${today.getFullYear()}-${addZeroToCompleteTwo(month)}-${addZeroToCompleteTwo(lastDay)}`
}

export const getCurrentMonthFirstDay = () => {
    const today = new Date();
    const month = today.getMonth() + 1;

    return `${today.getFullYear()}-${addZeroToCompleteTwo(month)}-01`
}

export const getToday = () => {
    return formateDateToYMD(new Date())
}

export const transformCompleteDateToJustDate = (completeDate: string) => {
    return formateDateToYMD(new Date(completeDate))
}

export const formateDateToYMD = (date: Date) => {
    const month = addZeroToCompleteTwo((date.getMonth()+1))
    const day = addZeroToCompleteTwo(date.getDate())

    return `${date.getFullYear()}-${month}-${day}`
}

export const calcDefaultDateValue = (defaultValue: string) => {
    if (defaultValue === '@@@TODAY') {
        return getToday()
    } else if (defaultValue === '@@@CURRENT_MONTH_FIRST') {
        return getCurrentMonthFirstDay()
    } else if (defaultValue === '@@@CURRENT_MONTH_LAST') {
        return getCurrentMonthLastDay()
    } else {
        return defaultValue
    }
}

