import { CSSProperties } from 'react'
import { colorsBrowse } from './colors'

export const styleComponentContainer: CSSProperties = {
    display: 'flex', 
    justifyContent: 'space-evenly',
    width: '100%',
}

export const styledSectionBox: CSSProperties = {
    borderStyle: 'ridge',
    borderWidth: '5px',
    borderColor: colorsBrowse.pairLine,
    margin: '10px',
    padding: '10px',
}
