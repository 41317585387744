import { Step } from './report.model'

export enum TabType {
    BROWSE = 'BROWSE',
    EDIT = 'EDIT',
    REPORT = 'REPORT',
}

export enum TypeOfAction {
    FORM = 'FORM',
    FUNCTION = 'FUNCTION'
}

export interface ActionDefinition {
    typeOfAction: TypeOfAction,
    actionId: string
}

// TODO: Check if we are still using this...
export enum BrowserFilterTypes {
    MATCH = 'MATCH',
    TEXT_SEARCH = "TEXT_SEARCH",
    MIN = "MIN",
    MAX = "MAX"
}

export enum Alignments {
    RIGHT = 'right',
    CENTER = 'center',
    LEFT = 'left'
}

export interface FilterConfig {
    caseSensitive?: boolean,
    excludeEquals?: boolean,
}

export interface FieldConfig {
    isRemote?: boolean,
    source?: string,
    sourceFilter?: string,
    showFields?: string[],
    valueField?: string,
    defaultValue?: any,
    placeholder?: string,
    decimals?: number,
    separator?: string,
    max?: number,
    min?: number,
}

export interface Title {
    name: string,
    field: string,
    fieldType?: FieldType,
    width?: string,
    align?: Alignments,
    config?: FieldConfig,
    operation?: string,
}

export interface Pagination {
    rowsPerPage: number,
    currentPage: number,
    totalRows?: number,
    forceUpdate?: number,
}
export interface BrowseFilter {
    title: string,
    filterId: string, // Id of the filter data
    field: string, // field to filter
    type: FieldType,
    filterType: BrowserFilterTypes,
    config?: FieldConfig,
    filterConfig?: FilterConfig,
    value?: any,
    applied?: any,
    hide?: boolean,
    copyValueFrom?: string,
}

export interface Section {
    id: string,
    type: 'Columns' | 'MasterDetails' | 'Includer'
    columns?: number,
}
export interface TabParams {
    tabType: TabType,
    dataId: string,
    id: string,
    showField?: string,
    title: string,
    idField: string,
    browseTitles?: Title[],
    editFields?: EditField[],
    sections?: Section[],
    pagination?: Pagination,
    filters?: BrowseFilter[],
    parameters?: BrowseFilter[],
    reportRenderType?: 'SIMPLE' | 'GROUPS',
    reportSteps?: Step[],
    reportGroups?: string[],
    cacheData?: any[],
    workDataOnSite?: boolean,
    endpoint?: string,
    canEdit?: boolean,
    onEdit?: ActionDefinition,
    canDelete?: boolean,
    onDelete?: ActionDefinition,
    insertTransformer?: string,
    updateTransformer?: string,
    deleteTransformer?: string,
}

export enum FieldType {
    SIMPLE_TEXT = "SIMPLE_TEXT",
    SEARCHBOX = "SEARCHBOX",
    INTEGER = "INTEGER",
    DATE = "DATE",
    MONEY = "MONEY",
    CHECK = "CHECK"
}

export interface EditField {
    field: string,
    label: string,
    tip?: string,
    type: FieldType,
    value?: any,
    oldValue?: any,
    show: boolean,
    required?: boolean,
    config?: FieldConfig,
    sectionId?: string,
}

// CONFIG.DEFAULTVALUE options:
// string (if type is a number, parse it!)
// @@@TODAY => save today Date
// @@@[TRANSFORMER FUNCTION NAME]
// @@@MAX (max value)
// @@@MIN (min value)
