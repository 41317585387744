import { CSSProperties } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Browse } from '../Browse/Browse.component';
import { Edit } from '../Edit/Edit.component';
import { Report } from '../Browse/Report.component'
import { TabSliceState, removeTab } from '../../../store/slices/tabs.slice';
import { selectTab } from '../../../store/slices/tabs.slice';
import { colorsActionButton } from '../../styles/colors';

const styleTabContainer: CSSProperties = {
    margin: '0px 15px',
    borderStyle: 'solid',
    borderBottom: '0px',
}
  
const styleTab: CSSProperties = {
    borderStyle: 'solid',
    borderRadius: '10px',
    borderBottom: '0px',
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '0px',
    padding: '5px 10px'
}

const styleSelectedTab: CSSProperties = {
    backgroundColor: colorsActionButton.SpecialBackground,
}

const styleSelectedTabText: CSSProperties = {
    color: 'white',
}

const styleUnselectedTabText: CSSProperties = {
    cursor: 'pointer'
}

const Tab = ({title, id, isSelected}: 
    {
        title: string, 
        id: string,
        isSelected: boolean,
    }) => {
    const dispatch = useDispatch()

    return (
        <span style={{...styleTab, ...(isSelected ? styleSelectedTab : null)}} >
            <span 
                style={{...(isSelected ? styleSelectedTabText : styleUnselectedTabText)}}
                onClick={() => dispatch(selectTab(id))}
            >
                {title}
            </span>
            <span 
                style={{...(isSelected ? styleSelectedTabText : null), ...styleUnselectedTabText}}
                onClick={() => dispatch(removeTab(id))}>
                    {` X`}
                </span>
        </span>
    )
}

export const TabsComponent = () => {
    const { openTabs, selected } =  useSelector((state: {tabs: TabSliceState}) => state.tabs)

    return (
        <>
            <div style={{margin: '20px 0px 5px 15px'}}>
                {openTabs.map((t) => 
                    <Tab 
                        key={t.id} 
                        title={t.title} 
                        id={t.id}
                        isSelected={t.id === selected}
                    />)}
            </div>
            <div style={styleTabContainer}>
                {
                    openTabs.filter(t => t.id === selected).map(t => {
                        if (t.tabType === 'BROWSE') {
                            return (<Browse 
                                key={t.id}
                                browseParams={t} 
                            />)
                        } else if (t.tabType === 'EDIT') {
                            return (<Edit 
                                key={t.id}
                                editParams={t} 
                            />)
                        } else if (t.tabType === 'REPORT') {
                            return (<Report 
                                key={t.id}
                                browseParams={t}
                            />)
                        }
                    })
                }
                {/* {openTabs.map(t => 
                (t.tabType === 'BROWSE' && t.id === selected) && <Browse 
                    key={t.id}
                    browseParams={t} 
                />
                )}
                {openTabs.map(t => 
                (t.tabType === 'EDIT' && t.id === selected) && <Edit 
                    key={t.id}
                    editParams={t} 
                />
                )} */}
            </div>
      </>
    )
}