import axios from 'axios';
import Cookies from 'universal-cookie';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiUrlBase } from '../data/api.data';

const cookies = new Cookies()

export const Login = () => {
    const [message, setMessage] = useState('Authorizing...')
    const navigate = useNavigate()
    const { appId } = useParams()

    const setAWSToken = async (code: string) => {
        if (code && appId && appId.length > 2) {
            try {
                const response = await axios.get(`${ApiUrlBase}/users/${appId}/validate/${code}`, {
                    headers: {
                        "http-equiv": "Content-Security-Policy",
                        "content": "upgrade-insecure-requests"
                    }
                })
                if (response?.data?.data) {
                    cookies.set('lock', response.data.data)
                }

                // This can be in the store as array
                if (appId === 'CTAS') {
                    navigate('/app/accounts')
                } else if (appId === 'CAVE') {
                    navigate('/app/cave')
                }
            } catch (error: any) {
                if (error.response.status === 404) {
                    setMessage('You dont have a license!')
                }
            }
        }
    }
    
    useEffect(() => {
        let urlParams = new URLSearchParams(window.location.search);
    
        if (urlParams.get('code')) {
            setAWSToken(urlParams.get('code') || '')
        }
    }, [])

    return (
        <h1>{message}</h1>
    )
}
