const mediumTone = '#9fbcc7'
const strongTone = '#135069'
const lightTone = '#d6ebf3'
const lightFontOverStrong = '#ffffff'

export const colorsActionButton = {
    NormalBackground: mediumTone,
    SpecialBackground: strongTone,
}

export const colorsCommandButton = {
    defaultBackground: strongTone,
    defaultFont: lightFontOverStrong,
}

export const colorsBrowse = {
    sectionBorder: mediumTone,
    pairLine: lightTone,
    titleBackground: strongTone,
    titleFont: lightFontOverStrong,
}