import { createSlice } from '@reduxjs/toolkit';
import { TabParams } from '../../app/models/tabs.model';
import { IActionMenu } from '../../app/Shared/Header/ActionMenu.component';
import { allDefinitions } from '../../app/data/allDefinitions';

export interface AppDefinition {
    appId: string,
    appUrl?: string,
    screens: TabParams[],
    menu: IActionMenu[],
    functions: Function[],
    filters?: any[],
    transformers?: any[],
    initialState: any,
}

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        appDefinition: undefined as undefined | AppDefinition,
        lang: undefined
    },
    reducers: {
        selectApp: (state, action: {payload: string}) => {
            const definition = allDefinitions.find(d => d.appId === action.payload)
            if (definition) {
                state.appDefinition = definition
            } 
        }
    }
})
    
export const { selectApp } = appSlice.actions
export default appSlice.reducer
