import { createSlice } from '@reduxjs/toolkit';

export enum NotificationType {
    SUCCESS = 'SUCCESS',
    WARNING = 'WARNING',
    ERROR = 'ERROR'
}

export interface NotificationModel {
    title: string,
    details?: string,
    type: NotificationType,
}

export interface TabSliceState {
    notifications: NotificationModel[],
}

export const notificationSlice = createSlice({
    name: 'notifications',
    initialState: {
        notifications: [] as NotificationModel[]
    },
    reducers: {
        addNotification: (state, action: {payload: NotificationModel}) => {
            state.notifications.push(action.payload);
        },
        removeLastNotification: (state) => {
            if (state.notifications.length) {
                state.notifications.shift()
            }
        }
    }
})

export const { addNotification, removeLastNotification } = notificationSlice.actions

export default notificationSlice.reducer