import { useState } from 'react'
import { FieldConfig } from '../../models/tabs.model'
import { ClearButton } from './ClearButton.component'

const isValidNumber = (number: string) => {
    const parts = number.split('')
    if (!(parts.every(p => p === '' || p.match(/[0-9]/g)?.join('') === p ))) {
        return false
    }
    return true
}
interface IntegerLabelProps {
    value: number, 
}
export const IntegerLabel = ({value}: IntegerLabelProps) => {

    return (
        <span>
            {value === undefined && ' -- '}
            {value !== undefined && value}
        </span>
    )
}

interface MoneyInputProps {
    value: any,
    config?: FieldConfig,
    onChangeHandler: Function,
    field: string,
    showClearButton?: boolean,
}
export const IntegerInput = ({value, config, onChangeHandler, field, showClearButton}: MoneyInputProps) => {
    const [ numericValue, setNumericValue  ] = useState<number>(value || parseFloat(config?.defaultValue || '0'))

    const isValid = (newValue: string) => {
        if (!isValidNumber(newValue)) {
            return false
        }

        // TODO: Add Min check!
        if (config?.max && parseInt(newValue) > config?.max) {
            return false
        }

        return true
    }

    const handleOnChange = (event: any) => {
        if (event.target.value === '' || event.target.value === undefined) {
            event.target.value = 0
        }
        if (isValid(event.target.value)) {
            setNumericValue(parseFloat(event.target.value))
            onChangeHandler(field, parseFloat(event.target.value))
        } else {
            event.target.value = numericValue
        }
    }

    return (
        <>
            <input 
                style={{width: `${(15 * (config?.max?.toString().length || 8) + 15* (config?.decimals || 0))}px`}}
                value={numericValue}
                onChange={handleOnChange}
            />
            {showClearButton && 
                <ClearButton action={() => handleOnChange({target: {value: config?.defaultValue || '0'}})}/>
            }
        </>
    )

}