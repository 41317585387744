import { useState, CSSProperties, ReactFragment } from 'react'
import { colorsActionButton, colorsCommandButton } from '../../styles/colors'
import { useDispatch, useSelector } from 'react-redux'
import { addTab } from '../../../store/slices/tabs.slice';
import { TabParams } from '../../models/tabs.model';
import { IActionMenu, IActionMenuAction } from '../Header/ActionMenu.component';
import { MENU_ACTION_TYPE } from '../../models/menu.model';
import { AppDefinition } from '../../../store/slices/app.slice';
import CTAS_APP_DEFINITION from '../../data/definitions/ctasAppDefinition';

const actionButtonStyle: CSSProperties = {
    padding: '5px 0px',
    margin: '0px 0px',
    width: '200px',
    height: '100%',
    backgroundColor: colorsActionButton.NormalBackground,
    borderStyle: 'none',
}

const actionButtonHover: CSSProperties = {
    fontWeight: 'bold',
}

const actionNewButtonStyle: CSSProperties = {
    padding: '0px 0px',
    marginLeft: '-30px',
    fontSize: '40px',
    height: '100%',
    width: '50px',
    borderStyle: 'none',
    backgroundColor: 'transparent'
}

const actionNewButtonHover: CSSProperties = {
    fontWeight: 'bold',
}

const SubMenuStyle: CSSProperties = {
    position: 'absolute',
    left: 0,
    top: '100%',
    backgroundColor: 'white',
    padding: '.75rem',
    borderRadius: '.25rem',
}

const SubMenu = ({options}: {options: IActionMenu[]}) => {
    return (
        <ul style={SubMenuStyle}>
            {
                options.map((o, index) => 
                    <ActionButton 
                        actionMenu={o}
                        key={index}
                    >
                        {o.title}
                    </ActionButton>
                )
            }
        </ul>
    )
}


export const ActionButton = (props: {
    actionMenu: IActionMenu, 
    isSubMenu?: boolean, 
    closeMenu?: Function,
    children: ReactFragment,
}) => {
    const dispatch = useDispatch();
    const { appDefinition } = useSelector((state: {app: {appDefinition: AppDefinition}}) => state.app)

    const [buttonHover, setButtonHover] = useState(false)

    const handleClick = (actionType: string, actionId: string) => {
        if (actionType === MENU_ACTION_TYPE.NEW_TAB) {
            const newTabDefinition = appDefinition.screens.find(s => s.id === actionId)
            const newTab: TabParams = JSON.parse(JSON.stringify(newTabDefinition))

            if (newTab.editFields) {
                newTab.title = `Nueva ${newTab.title}`
            }
            dispatch(addTab(newTab))
        } else if (actionType === MENU_ACTION_TYPE.SUBMENU) {

        }
        if (props.closeMenu) {
            props.closeMenu()
        }
    }

    return (
        <>
            <button 
                style={{... actionButtonStyle, ...(buttonHover ? actionButtonHover : null)}} 
                onClick={() => handleClick(props.actionMenu.actionType, props.actionMenu.newTab || '')}
                onMouseEnter={()=>{setButtonHover(true)}}
                onMouseLeave={()=>{setButtonHover(false)}}
            >
                {props.children}
                {(props.actionMenu.actionType === MENU_ACTION_TYPE.SUBMENU && buttonHover) && <SubMenu options={props.actionMenu.subMenu || []}/>}
            </button>
        </>
    )
}

const commandButtonStyle: CSSProperties = {
    padding: '8px 0px',
    margin: '10px 10px',
    width: '150px',
    backgroundColor: colorsCommandButton.defaultBackground,
    color: colorsCommandButton.defaultFont,
    borderRadius: '5px',
}

const commandButtonHover: CSSProperties = {
    fontWeight: 'bold',
}

export const CommandButton = (props: { 
    dark?: boolean, 
    onClickHandler?: Function,
    disabled?: boolean,
    children: ReactFragment
}) => {
    const [buttonHover, setButtonHover] = useState(false)

    const onClickSureHandler = () => {
        if (props.onClickHandler) {
            props.onClickHandler()
        }
    }

    return (
        <button 
            style={{...commandButtonStyle, ...(buttonHover ? commandButtonHover : null)}} 
            onMouseEnter={()=>{setButtonHover(true)}}
            onMouseLeave={()=>{setButtonHover(false)}}
            onClick={onClickSureHandler}
            disabled={props.disabled}
        >
            {props.children}
        </button>
    )
}