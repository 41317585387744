import { AppDefinition } from '../../../store/slices/app.slice'
import { IActionMenu } from '../../Shared/Header/ActionMenu.component'
import { MENU_ACTION_TYPE } from '../../models/menu.model'
import { BrowserFilterTypes, FieldType, TabParams, TabType, TypeOfAction } from '../../models/tabs.model'

const editHolidays: TabParams = {
    tabType: TabType.EDIT,
    id: 'EDIT.HOLIDAYS',
    dataId: 'HOLIDAYS',
    idField: 'id',
    title: 'Feriado',
    showField: 'name',
    editFields: [
        {
            field: 'id',
            label: 'Id',
            type: FieldType.INTEGER,
            show: false,
        },
        {
            field: 'holiday',
            label: 'Fecha',
            type: FieldType.DATE,
            show: true,
        },
        {
            field: 'name',
            label: 'Nombre',
            type: FieldType.SIMPLE_TEXT,
            show: true,
        },
    ],
}

const browseHolidays: TabParams = {
    tabType: TabType.BROWSE,
    id: 'BROWSE.HOLIDAYS',
    dataId: 'HOLIDAYS',
    idField: 'id',
    title: 'Sucursales',
    browseTitles: [
        {
            name: 'Fecha', 
            field: 'holiday', 
            width: '30%',
            fieldType: FieldType.DATE,
        },
        {
            name: 'Nombre de Feriado', 
            field: 'name', 
            width: '60%',
            fieldType: FieldType.SIMPLE_TEXT,
        },
    ],
    workDataOnSite: true,
    canEdit: true,
    onEdit: {
        typeOfAction: TypeOfAction.FORM,
        actionId: 'EDIT.HOLIDAYS'
    },
    canDelete: true,
    pagination: {
        currentPage: 1,
        rowsPerPage: 10,
        totalRows: undefined,
    },
    filters: [
        {
            filterId: 'dateFrom',
            type: FieldType.DATE,
            filterType: BrowserFilterTypes.MIN,
            field: 'holiday',
            title: 'Desde Fecha',
            config: {
                defaultValue: '@@@CURRENT_MONTH_FIRST'
            }
        },
        {
            filterId: 'dateTo',
            type: FieldType.DATE,
            filterType: BrowserFilterTypes.MAX,
            field: 'holiday',
            title: 'Hasta Fecha',
            config: {
                defaultValue: '@@@CURRENT_MONTH_LAST'
            }
        },
        {
            filterId: 'branchSearch',
            type: FieldType.SIMPLE_TEXT,
            filterType: BrowserFilterTypes.TEXT_SEARCH,
            field: 'name',
            title: 'Por Sucursal',
        }
    ]
}

const editBranches: TabParams = {
    tabType: TabType.EDIT,
    id: 'EDIT.BRANCHES',
    dataId: 'BRANCHES',
    idField: 'id',
    title: 'Sucursal',
    showField: 'name',
    editFields: [
        {
            field: 'id',
            label: 'Id',
            type: FieldType.INTEGER,
            show: false,
        },
        {
            field: 'bankId',
            label: 'Banco',
            type: FieldType.SEARCHBOX,
            config: {
                isRemote: false,
                source: 'BANKS',
                valueField: 'id',
                showFields: ['shortName'],
                placeholder: 'seleccione un banco',
            },
            show: true,
        },
        {
            field: 'branchId',
            label: 'Código Suc.',
            type: FieldType.INTEGER,
            config: {
                max: 999,
                min: 1,
            },
            show: true,
        },
        {
            field: 'name',
            label: 'Sucursal',
            type: FieldType.SIMPLE_TEXT,
            show: true,
        },
    ]
}

const browseBranches: TabParams = {
    tabType: TabType.BROWSE,
    id: 'BROWSE.BRANCHES',
    dataId: 'BRANCHES',
    idField: 'id',
    title: 'Sucursales',
    browseTitles: [
        {
            name: 'Banco', 
            field: 'bankId', 
            width: '30%',
            fieldType: FieldType.SEARCHBOX,
            config: {
                isRemote: false,
                source: 'BANKS',
                valueField: 'id',
                showFields: ['code', 'shortName'],
                separator: ' - '
            }
        },
        {
            name: 'Codigo', 
            field: 'branchId', 
            width: '10%',
        },
        {
            name: 'Sucursal', 
            field: 'name', 
            width: '50%',
        },
    ],
    workDataOnSite: true,
    canEdit: true,
    onEdit: {
        typeOfAction: TypeOfAction.FORM,
        actionId: 'EDIT.BRANCHES'
    },
    canDelete: true,
    pagination: {
        currentPage: 1,
        rowsPerPage: 10,
        totalRows: undefined,
    },
    filters: [
        {
            filterId: 'bankSearch',
            type: FieldType.SEARCHBOX,
            filterType: BrowserFilterTypes.MATCH,
            field: 'bankId',
            title: 'Por Banco',
            config: {
                isRemote: false,
                source: 'BANKS',
                valueField: 'id',
                showFields: ['code', 'shortName']
            }
        },
        {
            filterId: 'branchSearch',
            type: FieldType.SIMPLE_TEXT,
            filterType: BrowserFilterTypes.TEXT_SEARCH,
            field: 'name',
            title: 'Por Sucursal',
        }
    ]
}

const editBanks: TabParams = {
    tabType: TabType.EDIT,
    id: 'EDIT.BANKS',
    dataId: 'BANKS',
    idField: 'id',
    title: 'Banco',
    showField: 'shortName',
    editFields: [
        {
            field: 'id',
            label: 'Id',
            type: FieldType.INTEGER,
            show: false,
        },
        {
            field: 'code',
            label: 'Código',
            type: FieldType.INTEGER,
            config: {
                max: 999,
                min: 1
            },
            show: true,
        },
        {
            field: 'shortName',
            label: 'Nombre Corto',
            type: FieldType.SIMPLE_TEXT,
            show: true,
        },
        {
            field: 'fullName',
            label: 'Nombre Entero',
            type: FieldType.SIMPLE_TEXT,
            show: true,
        },
    ]
}

const browseBanks: TabParams = {
    tabType: TabType.BROWSE,
    id: 'BROWSE.BANKS',
    dataId: 'BANKS',
    idField: 'id',
    title: 'Bancos',
    browseTitles: [
        {name: 'Código', field: 'code', width: '10%'},
        {name: 'Nombre Corto', field: 'shortName', width: '30%'},
        {name: 'Nombre Entero', field: 'fullName', width: '50%'},
    ],
    workDataOnSite: true,
    canEdit: true,
    onEdit: {
        typeOfAction: TypeOfAction.FORM,
        actionId: 'EDIT.BANKS'
    },
    canDelete: true,
    pagination: {
        currentPage: 1,
        rowsPerPage: 10,
        totalRows: undefined,
    },
    filters: [
        {
            filterId: 'nameSearch',
            type: FieldType.SIMPLE_TEXT,
            filterType: BrowserFilterTypes.TEXT_SEARCH,
            field: 'shortName',
            title: 'Por Nombre',
        },
        {
            filterId: 'idSearch',
            type: FieldType.INTEGER,
            filterType: BrowserFilterTypes.MATCH,
            field: 'id',
            title: 'Por Código'
        },
    ]
}

const editCustomers: TabParams = {
    tabType: TabType.EDIT,
    id: 'EDIT.CUSTOMERS',
    dataId: 'CUSTOMERS',
    idField: 'id',
    title: 'Cliente',
    showField: 'name',
    editFields: [
        {
            field: 'id',
            label: 'Id',
            type: FieldType.INTEGER,
            show: false,
        },
        {
            field: 'name',
            label: 'Nombre',
            type: FieldType.SIMPLE_TEXT,
            show: true,
        },
        {
            field: 'email',
            label: 'Email',
            type: FieldType.SIMPLE_TEXT,
            show: true,
        },
    ]
}

const browseCustomers: TabParams = {
    tabType: TabType.BROWSE,
    id: 'BROWSE.CUSTOMERS',
    dataId: 'CUSTOMERS',
    idField: 'id',
    title: 'Clientes',
    browseTitles: [
        {name: 'Nombre', field: 'name', width: '45%'},
        {name: 'Email', field: 'email', width: '45%'},
    ],
    workDataOnSite: true,
    canEdit: true,
    onEdit: {
        typeOfAction: TypeOfAction.FORM,
        actionId: 'EDIT.CUSTOMERS'
    },
    canDelete: true,
    pagination: {
        currentPage: 1,
        rowsPerPage: 10,
        totalRows: undefined,
    },
    filters: [
        {
            filterId: 'nameSearch',
            type: FieldType.SIMPLE_TEXT,
            filterType: BrowserFilterTypes.TEXT_SEARCH,
            field: 'name',
            title: 'Por Nombre',
        },
        {
            filterId: 'emailSearch',
            type: FieldType.SIMPLE_TEXT,
            filterType: BrowserFilterTypes.TEXT_SEARCH,
            field: 'email',
            title: 'Por Email'
        },
    ]
}

const mainMenu: IActionMenu[] = [
    {
        title: 'Clientes',
        actionType: MENU_ACTION_TYPE.SUBMENU,
        subMenu: [
            {
                title: 'Buscar Clientes',
                actionType: MENU_ACTION_TYPE.NEW_TAB,
                newTab: 'BROWSE.CUSTOMERS',
            },
            {
                title: 'Nuevo Cliente',
                actionType: MENU_ACTION_TYPE.NEW_TAB,
                newTab: 'EDIT.CUSTOMERS'
            }
        ]
    },
    {
        title: 'Generales',
        actionType: MENU_ACTION_TYPE.SUBMENU,
        subMenu: [
            {
                title: 'Buscar Bancos',
                actionType: MENU_ACTION_TYPE.NEW_TAB,
                newTab: 'BROWSE.BANKS'
            },
            {
                title: 'Nuevo Banco',
                actionType: MENU_ACTION_TYPE.NEW_TAB,
                newTab: 'EDIT.BANKS'
            },
            {
                title: 'Buscar Sucursales',
                actionType: MENU_ACTION_TYPE.NEW_TAB,
                newTab: 'BROWSE.BRANCHES'
            },
            {
                title: 'Nueva Sucursal',
                actionType: MENU_ACTION_TYPE.NEW_TAB,
                newTab: 'EDIT.BRANCHES'
            },
            {
                title: 'Buscar Feriados',
                actionType: MENU_ACTION_TYPE.NEW_TAB,
                newTab: 'BROWSE.HOLIDAYS'
            },
            {
                title: 'Nuevo Feriado',
                actionType: MENU_ACTION_TYPE.NEW_TAB,
                newTab: 'EDIT.HOLIDAYS'
            },
        ]
    }
]

const CAVE_APP_DEFINITIONS: AppDefinition = {
    appId: 'CAVE',
    screens: [
        editCustomers,
        browseCustomers,
        editBanks,
        browseBanks,
        editBranches,
        browseBranches,
        editHolidays,
        browseHolidays,
    ],
    menu: mainMenu,
    functions: [],
    initialState: {
        customers: {
            fullList: [],
            loading: false,
        },
        banks: {
            fullList: [],
            loading: false,
        },
        branches: {
            fullList: [],
            loading: false,
        },
        holidays: {
            fullList: [],
            loading: false,
        },
    },
}

export default CAVE_APP_DEFINITIONS