import { ThunkDispatch } from '@reduxjs/toolkit';
import { CSSProperties, useState } from 'react';
import { BsFillPencilFill, BsFillTrashFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { deleteData } from '../../../store/slices/data.slice';
import { addTab } from '../../../store/slices/tabs.slice';
import { FieldType, TabParams, Title, TypeOfAction } from '../../models/tabs.model';
import { colorsActionButton, colorsBrowse } from '../../styles/colors';
import { valuesToTab } from './browse.helper';
import { rowHeight } from './browse.styles';
import { DateLabel } from '../Controls/date.control';
import { SearchBoxLabel } from '../Controls/search-box.control';
import { AppDefinition } from '../../../store/slices/app.slice';
import { MoneyLabel } from '../Controls/Money.control ';

const FieldLabel = (value: any, title: Title) => {
    if (title.fieldType === FieldType.SIMPLE_TEXT) {
        return value
    } else if (title.fieldType === FieldType.DATE) {
        return DateLabel(value)
    } else if (title.fieldType === FieldType.MONEY) {
        return MoneyLabel({value})
    } else if (title.fieldType === FieldType.SEARCHBOX) {
        return SearchBoxLabel(value, title)
    // } else if (fieldProp.type === FieldType.CHECK) {
    //     return CheckInput(fieldProp)
    } else {
        return value
        // return (<p>No Valid Ctrl Type</p>)
    }
    
}

const styleTitleRow: CSSProperties = {
    backgroundColor: colorsBrowse.titleBackground, 
    color: colorsBrowse.titleFont,
} 

// TODO: showActions es el numero de Acciones y debería servir para calcular el ancho de la columna
export const BrowseTitle = ({titles, showActions} : {titles: Title[], showActions?: number}) => {
    return (
        <tr style={styleTitleRow}>
            {showActions && <td style={{width: '10%', padding: '10px'}} align='center'>Actions</td>}
            {titles.map(t => <td style={{width: t.width}} align='center' key={t.field}>{t.name}</td>)}
        </tr>
    )
}

export const BrowseRowBlank = () => {
    return (
        <tr style={{height: rowHeight}}>
            <td></td>
        </tr>
    )
}

export const NoInfoToShow = ({columnsQty}: {columnsQty: number}) => {
    return (
        <tr>
            <td colSpan={columnsQty} style={{textAlign: 'center'}}>No hay Información para Mostrar</td>
        </tr>
    )
}

export const ReportGroupHeader = ({browseParams, value}: {
    browseParams: TabParams, value: any
}) => {
    return (
        <tr>
            <td 
                style={{
                    backgroundColor: colorsActionButton.NormalBackground,
                    padding: '16px 40px'
                }} 
                colSpan={browseParams.browseTitles?.length}>
                    <span style={{fontWeight: 'bold'}}>
                        {value.title?.name || ''}:
                    </span> {FieldLabel (value.value, value.title)}
            </td>
        </tr>
    )
}

export const ReportRow = ({browseParams, values}: {
    browseParams: TabParams, values: any
}) => {
    const [hover, setHover] = useState(false);

    return (
        <tr 
            key={values[browseParams.idField]} 
            onMouseEnter={() => {setHover(true)}}
            onMouseLeave={() => {setHover(false)}}
            style={{...(hover ? {backgroundColor: colorsBrowse.pairLine} : {}), height: rowHeight}}
        >
            {browseParams.browseTitles && browseParams.browseTitles.map(t => 
            <td 
                key={t.field} 
                style={{padding: '0px 8px', ...(t.align ? {textAlign: t.align} : null)}}>
                {FieldLabel (values[t.field], t)}
            </td>)}
        </tr>
    )
}

export const BrowseRow = ({browseParams, values} 
    : {browseParams: TabParams, values: any}
) => {
    const { appDefinition } = useSelector((state: {app: {appDefinition: AppDefinition}}) => state.app)
    const [hover, setHover] = useState(false);
    const dispatch = useDispatch()
    const dispatchWithId = useDispatch<ThunkDispatch<any, number, any>>()

    const onEditClick = () => {
        const onEdit = browseParams.onEdit
        if (onEdit?.typeOfAction === TypeOfAction.FORM) {
            const onEditAction = appDefinition.screens.find(s => s.id === onEdit.actionId)
            const newTab: TabParams = JSON.parse(JSON.stringify(onEditAction))
            newTab.id = `${browseParams.id}${values[browseParams.idField]}`
            newTab.title = `${newTab.title} - ${values[newTab.showField || '']}`
            valuesToTab(values, newTab)
            dispatch(addTab(newTab))
        }
    }

    const onDeleteClick = () => {
        const id = values[browseParams.idField]
        dispatchWithId(deleteData({
            id,
            dataType: browseParams.dataId
        }))
    }

    return (
        <tr 
            key={values[browseParams.idField]} 
            onMouseEnter={() => {setHover(true)}}
            onMouseLeave={() => {setHover(false)}}
            style={{...(hover ? {backgroundColor: colorsBrowse.pairLine} : {}), height: rowHeight}}
        >
            <td> 
                <div style={{display: 'flex', justifyContent: 'space-evenly', margin: '8px'}}>
                    {browseParams.canEdit && <BsFillPencilFill onClick={onEditClick} />}
                    {browseParams.canDelete && <BsFillTrashFill onClick={onDeleteClick}/>}
                </div>
            </td>
            {browseParams.browseTitles && browseParams.browseTitles.map(t => 
            <td 
                key={t.field} 
                style={{padding: '0px 8px', ...(t.align ? {textAlign: t.align} : null)}}>
                {FieldLabel (values[t.field], t)}
            </td>)}
        </tr>
    )
}
