import { useEffect } from 'react'
import { FieldConfig } from '../../models/tabs.model'
import { ClearButton } from './ClearButton.component'
import { calcDefaultDateValue, transformCompleteDateToJustDate } from './date.helper'

// In the future, add Configs as Params...
export const DateLabel = (value: string) => {

    return (
        <span>
            {value && new Date(value).toLocaleDateString()}
        </span>
    )
}

interface DateInputProps {
    field: string,
    value: any,
    onChangeHandler: Function,
    config?: FieldConfig,
    showClearButton?: boolean,
}
export const DateInput = ({field, value, onChangeHandler, config, showClearButton}: DateInputProps) => {

    useEffect(() => {
        if (!value) {
            onChangeHandler(field, calcDefaultDateValue(config?.defaultValue))
        } else if (value.length > '10') {
            onChangeHandler(field, transformCompleteDateToJustDate(value))
        } else {
            onChangeHandler(field, value)
        }
    }, [])

    const handleOnChange = (event: any) => {
        onChangeHandler(field, event.target.value || calcDefaultDateValue(config?.defaultValue))
    }

    return (
        <>
            <input 
                type={'date'} 
                value={value}
                onChange={handleOnChange}
            />
            {showClearButton && 
                <ClearButton action={() => handleOnChange({target: {value: undefined}})}/>
            }
        </>
    )
}