import { CSSProperties } from 'react'
import { styledSectionBox } from '../../styles/shared-components.style'
import { CommandButton } from '../Buttons/ActionButton.component'
import { BrowseFilter, FieldType, TabParams } from '../../models/tabs.model'
import { SearchBoxInput } from '../Controls/search-box.control'
import { useDispatch } from 'react-redux'
import { applyFilters, updateFilterValues, updateParametersValues } from '../../../store/slices/tabs.slice'
import { TextInput } from '../Controls/Input.control'
import { DateInput } from '../Controls/date.control'
import { MoneyInput } from '../Controls/Money.control '

const styleFiltersContainer: CSSProperties = {
    ...styledSectionBox,
    width: '30%',
}

interface IProps  {
    filter: BrowseFilter,
    setFilter: Function,
}

const Filter = ({filter, setFilter}: IProps) => {

    return (
        <div style={{display: 'flex', justifyContent: 'space-evenly', margin: '10px 0px'}}>
            <span>{filter.title}</span>
            {filter.type === FieldType.SEARCHBOX && 
                <SearchBoxInput
                    field={filter.filterId}
                    label={filter.title}
                    type={filter.type}
                    show= {true}
                    config={filter.config}
                    value={filter.value}
                    onChangeHandler={setFilter}
                    showClearButton={true}
                />
            }
            {filter.type === FieldType.SIMPLE_TEXT &&
                <TextInput
                    field={filter.filterId}
                    value={filter.value}
                    onChangeHandler={setFilter}
                    showClearButton={true}
                />
            }
            {filter.type === FieldType.MONEY &&
                <MoneyInput
                    field={filter.filterId}
                    value={filter.value}
                    onChangeHandler={setFilter}
                    config={filter.config}
                    showClearButton={true}
                />
            }
            {filter.type === FieldType.DATE &&
                <DateInput
                    field={filter.filterId}
                    value={filter.value}
                    onChangeHandler={setFilter}
                    config={filter.config}
                    showClearButton={true}
                />
            }
        </div>
    )
}

export const Filters = ({tab, generateReport}: {tab: TabParams, generateReport?: Function}) => {
    const dispatch = useDispatch()

    // This updates the field value in state
    const fieldFilterChangeHandler = (field: string, value: any) => {
        dispatch(updateFilterValues({tabId: tab.id, filterId: field, newValue: value}))
    }
    const fieldParameterChangeHandler = (field: string, value: any) => {
        dispatch(updateParametersValues({tabId: tab.id, parameterId: field,  newValue: value}))
    }

    const clickApplyFilters = () => {
        dispatch(applyFilters({tabId: tab.id}))
    }
    
    return (
        <div style={styleFiltersContainer}>
            {tab.parameters?.length && tab.parameters.filter(p => !p.hide).map(p => 
                <Filter 
                    key={p.title} 
                    filter={p}
                    setFilter={fieldParameterChangeHandler}
                />
            )}
            {tab.parameters?.length && 
                <div style={{display: 'flex', justifyContent: 'space-evenly', margin: '30px'}}>
                    <CommandButton 
                        onClickHandler={generateReport}
                        disabled={tab.parameters.every(p => p.applied === p.value)}
                    >
                        Generar Reporte
                    </CommandButton>
                </div>
            }
            {tab.filters?.length && tab.filters.filter(f => !f.hide).map(f => 
                <Filter 
                    key={f.title} 
                    filter={f}
                    setFilter={fieldFilterChangeHandler}
                />
            )}
            {tab.filters?.length && 
                <div style={{display: 'flex', justifyContent: 'space-evenly', margin: '30px'}}>
                    <CommandButton 
                        onClickHandler={clickApplyFilters}
                        disabled={tab.filters.every(f => f.applied === f.value)}
                    >
                        Aplicar Filtro
                    </CommandButton>
                </div>
            }
        </div>
    )
}
