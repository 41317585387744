import { AppDefinition } from '../../../store/slices/app.slice'
import { IActionMenu } from '../../Shared/Header/ActionMenu.component'
import { MENU_ACTION_TYPE } from '../../models/menu.model'
import { StepType } from '../../models/report.model'
import { FieldType, Alignments, TabParams, TypeOfAction, BrowserFilterTypes, TabType } from '../../models/tabs.model'

const filters = [{
    name: 'browseClients',
    function: (record: any) => {
        return record.isClient
    }
}]

const transformers = [
    {
        name: 'ventaSave',
        function: (record: any) => {
            return {
                ...record,
                actionType: 'SELL'
            }
        }
    }
]

const initialState = {
    person: {
        fullList: [],
        loading: false,
    },
    action: {
        fullList: [],
        loading: false,
    },
    customer: {
        fullList: [],
        loading: false,
    },
    sale: {
        fullList: [],
        loading: false,
    },
}

// START CUSTOMER
const browseCustomer: TabParams = {
    tabType: TabType.BROWSE,
    id: 'BROWSE.CUSTOMER',
    dataId: 'CUSTOMER',
    idField: 'id',
    title: 'Clientes',
    browseTitles: [
        {name: 'Nombre', field: 'fullName', width: '45%'},
        {name: 'CUIT/CUIL', field: 'taxId', width: '45%'},
    ],
    workDataOnSite: true,
    canEdit: true,
    onEdit: {
        typeOfAction: TypeOfAction.FORM,
        actionId: 'EDIT.CUSTOMER'
    },
    canDelete: true,
    pagination: {
        currentPage: 1,
        rowsPerPage: 10,
        totalRows: undefined,
    },
    filters: [
        {
            filterId: 'nameSearch',
            type: FieldType.SIMPLE_TEXT,
            filterType: BrowserFilterTypes.TEXT_SEARCH,
            field: 'fullName',
            title: 'Buscar Nombre',
        },
        {
            filterId: 'mailSearch',
            type: FieldType.SIMPLE_TEXT,
            filterType: BrowserFilterTypes.TEXT_SEARCH,
            field: 'email',
            title: 'Buscar Email',
        },
    ]
}

const editCustomer: TabParams = {
    tabType: TabType.EDIT,
    id: 'EDIT.CUSTOMER',
    dataId: 'CUSTOMER',
    idField: 'id',
    title: 'Cliente',
    showField: 'fullName',
    editFields: [
        {
            field: 'id',
            label: 'Id',
            type: FieldType.INTEGER,
            show: false
        },
        {
            field: 'fullName',
            label: 'Nombre Completo',
            type: FieldType.SIMPLE_TEXT,
            show: true,
            required: true,
        },
        {
            field: 'taxId',
            label: 'CUIT/CUIL',
            type: FieldType.SIMPLE_TEXT,
            show: true,
        },
        {
            field: 'email',
            label: 'Email',
            type: FieldType.SIMPLE_TEXT,
            show: true,
        },
    ]
}

// END CUSTOMER

// START TRANSACTION

const browseSale: TabParams = {
    tabType: TabType.BROWSE,
    id: 'BROWSE.SALE',
    dataId: 'SALE',
    idField: 'id',
    title: 'Ventas',
    browseTitles: [
        {
            name: 'Fecha', 
            field: 'txDate', 
            width: '20%', 
            fieldType: FieldType.DATE, 
            align: Alignments.CENTER
        },
        {
            name: 'Cliente', 
            field: 'customerId', 
            width: '45%', 
            fieldType: FieldType.SEARCHBOX,
            config: {
                isRemote: false,
                source: 'CUSTOMER',
                valueField: 'id',
                showFields: ['fullName'],
            }
        },
        {
            name: 'Importe', 
            field: 'amount', 
            width: '25%', 
            fieldType: FieldType.MONEY, 
            align: Alignments.RIGHT
        },
    ],
    workDataOnSite: true,
    pagination: {
        currentPage: 1,
        rowsPerPage: 10,
        totalRows: undefined,
    },
    filters: [
        {
            filterId: 'fullName', 
            type: FieldType.SEARCHBOX, 
            filterType: BrowserFilterTypes.MATCH,
            field: 'customerId',
            title: 'Cliente',
            config: {
                isRemote: false,
                source: 'CUSTOMER',
                valueField: 'id',
                showFields: ['fullName'],
            }
        },
        {
            filterId: 'importeFrom',
            type: FieldType.MONEY,
            filterType: BrowserFilterTypes.MIN,
            field: 'amount',
            title: 'Importe Minimo',
            config: {
                decimals: 2,
                max: 999999999,
                min: 0,
                separator: '.',
                defaultValue: '0',
            }
        },
        {
            filterId: 'importeTo',
            type: FieldType.MONEY,
            filterType: BrowserFilterTypes.MAX,
            field: 'amount',
            title: 'Importe Máximo',
            value: 999999999,
            config: {
                decimals: 2,
                max: 999999999,
                min: 0,
                separator: '.',
                defaultValue: '999999999',
            }
        },
        {
            filterId: 'fechaFrom',
            type: FieldType.DATE,
            filterType: BrowserFilterTypes.MIN,
            field: 'txDate',
            title: 'Desde Fecha',
            config: {
                defaultValue: '@@@CURRENT_MONTH_FIRST'
            }
        },
        {
            filterId: 'fechaTo',
            type: FieldType.DATE,
            filterType: BrowserFilterTypes.MAX,
            field: 'txDate',
            title: 'Hasta Fecha',
            config: {
                defaultValue: '@@@CURRENT_MONTH_LAST'
            }
        },
    ],
    canEdit: true,
    onEdit: {
        typeOfAction: TypeOfAction.FORM,
        actionId: 'EDIT.SALE'
    },
    canDelete: true,
}

const editSale: TabParams = {
    tabType: TabType.EDIT,
    id: 'EDIT.SALE',
    dataId: 'SALE',
    idField: 'id',
    title: 'Venta',
    showField: 'id',
    editFields: [
        {
            field: 'id',
            label: 'Id',
            type: FieldType.INTEGER,
            show: false
        },
        {
            field: 'actionId',
            label: 'Tipo',
            type: FieldType.INTEGER,
            show: false,
            value: 1,
        },
        {
            field: 'txDate',
            label: 'Fecha',
            type: FieldType.DATE,
            config: {
                defaultValue: '@@@TODAY',
            },
            show: true,
            required: true,
        },
        {
            field: 'customerId',
            label: 'Cliente',
            type: FieldType.SEARCHBOX,
            config: {
                isRemote: false,
                source: 'CUSTOMER',
                valueField: 'id',
                showFields: ['fullName'],
                placeholder: 'seleccione uno',
            },
            show: true,
            required: true,
        },
        {
            field: 'amount',
            label: 'Importe',
            type: FieldType.MONEY,
            config: {
                decimals: 2,
                max: 999999999,
                min: 0,
                separator: '.',
                defaultValue: '0',
            },
            show: true,
            required: true,
        },
        {
            field: 'observation',
            label: 'Descripción',
            type: FieldType.SIMPLE_TEXT,
            show: true,
            required: true,
        },
    ]
}

const reportSaleBalances: TabParams = {
    tabType: TabType.REPORT,
    id: 'REPORT.SALE_BALANCES',
    dataId: 'SALE',
    idField: 'id', // NECESARIO?
    title: 'Saldo Clientes',
    browseTitles: [
        {
            name: 'Cliente', 
            field: 'customerId', 
            width: '45%', 
            fieldType: FieldType.SEARCHBOX,
            config: {
                isRemote: false,
                source: 'CUSTOMER',
                valueField: 'id',
                showFields: ['fullName'],
            }
        },
        {
            name: 'Importe', 
            field: 'SUM.amount', 
            width: '25%', 
            fieldType: FieldType.MONEY, 
            align: Alignments.RIGHT
        },
    ],
    workDataOnSite: true,
    pagination: {
        currentPage: 1,
        rowsPerPage: 10,
        totalRows: undefined,
    },
    parameters: [
        {
            filterId: 'fullName', 
            type: FieldType.SEARCHBOX, 
            filterType: BrowserFilterTypes.MATCH,
            field: 'customerId',
            title: 'Cliente',
            config: {
                isRemote: false,
                source: 'CUSTOMER',
                valueField: 'id',
                showFields: ['fullName'],
            }
        },
        {
            filterId: 'fechaFrom',
            type: FieldType.DATE,
            filterType: BrowserFilterTypes.MIN,
            field: 'txDate',
            title: 'Desde Fecha',
            config: {
                defaultValue: '@@@CURRENT_MONTH_FIRST'
            }
        },
        {
            filterId: 'fechaTo',
            type: FieldType.DATE,
            filterType: BrowserFilterTypes.MAX,
            field: 'txDate',
            title: 'Hasta Fecha',
            config: {
                defaultValue: '@@@CURRENT_MONTH_LAST'
            }
        },
    ],
    reportRenderType: 'SIMPLE',
    reportSteps: [
        {
            stepType: StepType.FILTER,
            stepFilters: ['fechaFrom', 'fechaTo'],
            stepDestination: 'filtradoFechas'
        },
        {
            stepType: StepType.SUM,
            stepSource: 'filtradoFechas',
            stepDestination: 'sumarizado',
            stepTitles: [
                {
                    name: 'Cliente', 
                    field: 'customerId', 
                    fieldType: FieldType.SEARCHBOX,
                    operation: 'GROUP'
                },
                {
                    name: 'De mas',
                    field: 'observation',
                    fieldType: FieldType.SIMPLE_TEXT,
                },    
                {
                    name: 'Importe',
                    field: 'amount', 
                    operation: 'SUM',
                    fieldType: FieldType.MONEY, 
                },
            ]
        },
    ],
    filters: [
        {
            filterId: 'importeFrom',
            type: FieldType.MONEY,
            filterType: BrowserFilterTypes.MIN,
            field: 'SUM.amount',
            title: 'Saldo Minimo',
            config: {
                decimals: 2,
                max: 999999999,
                min: 0,
                separator: '.',
            }
        },
        {
            filterId: 'importeTo',
            type: FieldType.MONEY,
            filterType: BrowserFilterTypes.MAX,
            field: 'SUM.amount',
            title: 'Saldo Máximo',
            value: 999999999,
            config: {
                decimals: 2,
                max: 999999999,
                min: 0,
                separator: '.',
                defaultValue: '99999',
            }
        },
    ],
}

const reportSaleSummary: TabParams = {
    tabType: TabType.REPORT,
    id: 'REPORT.SALE_SUMMARY',
    dataId: 'SALE',
    idField: 'id', // NECESARIO?
    title: 'Resumen Cta Clientes',
    browseTitles: [
        {
            name: 'Fecha', 
            field: 'txDate',
            fieldType: FieldType.DATE,
            width: '15%'
        },
        {
            name: 'Description',
            field: 'observation',
            fieldType: FieldType.SIMPLE_TEXT,
            width: '45%'
        },
        {
            name: 'Importe',
            field: 'amount', 
            fieldType: FieldType.MONEY, 
            width: '15%'
        },
        {
            name: 'Saldo',
            field: 'balance',
            fieldType: FieldType.MONEY,
            width: '15%',
            align: Alignments.RIGHT
        }
    ],
    workDataOnSite: true,
    pagination: {
        currentPage: 1,
        rowsPerPage: 10,
        totalRows: undefined,
    },
    parameters: [
        {
            filterId: 'fechaFrom',
            type: FieldType.DATE,
            filterType: BrowserFilterTypes.MIN,
            field: 'txDate',
            title: 'Desde Fecha',
            config: {
                defaultValue: '@@@CURRENT_MONTH_FIRST'
            }
        },
        {
            filterId: 'fechaFromSaldo',
            type: FieldType.DATE,
            filterType: BrowserFilterTypes.MAX,
            field: 'txDate',
            hide: true,
            title: '-- Usada para Saldo --',
            copyValueFrom: 'fechaFrom',
            filterConfig: {
                excludeEquals: true,
            }
        },
        {
            filterId: 'fechaTo',
            type: FieldType.DATE,
            filterType: BrowserFilterTypes.MAX,
            field: 'txDate',
            title: 'Hasta Fecha',
            config: {
                defaultValue: '@@@CURRENT_MONTH_LAST'
            }
        },
    ],
    filters: [
        {
            filterId: 'fullName', 
            type: FieldType.SEARCHBOX, 
            filterType: BrowserFilterTypes.MATCH,
            field: 'customerId',
            title: 'Cliente',
            config: {
                isRemote: false,
                source: 'CUSTOMER',
                valueField: 'id',
                showFields: ['fullName'],
            }
        },

    ],
    reportRenderType: 'GROUPS',
    reportSteps: [
        {
            stepType: StepType.FILTER,
            stepFilters: ['fechaFromSaldo'],
            stepDestination: 'paraSaldoInicial'
        },
        {
            stepType: StepType.SUM,
            stepSource: 'paraSaldoInicial',
            stepDestination: 'saldos',
            stepTitles: [
                {
                    name: 'Cliente', 
                    field: 'customerId', 
                    fieldType: FieldType.SEARCHBOX,
                    operation: 'GROUP'
                },
                {
                    name: 'Fecha', 
                    field: 'txDate',
                    fieldType: FieldType.DATE,
                    operation: 'USE_PARAM_VALUE',
                    config: {
                        valueField: 'fechaFromSaldo'
                    }
                },
                {
                    name: 'Observation',
                    field: 'observation',
                    fieldType: FieldType.SIMPLE_TEXT,
                    config: {
                        defaultValue: 'Saldo Anterior'
                    },
                    operation: 'USE_DEF_VALUE'
                },
                {
                    name: 'Importe',
                    field: 'amount', 
                    operation: 'SUM',
                    fieldType: FieldType.MONEY, 
                    config: {
                        valueField: 'amount'
                    }
                },
            ]
        },
        {
            stepType: StepType.FILTER,
            stepDestination: 'lineas',
            stepFilters: ['fechaFrom', 'fechaTo']
        },
        {
            stepType: StepType.UNION,
            stepSources: ['saldos', 'lineas'],
            stepDestination: 'todasJuntas'
        },
        {
            stepType: StepType.CALC,
            stepSource: 'todasJuntas',
            stepDestination: 'calcSaldos',
            stepTitles: [
                {
                    name: 'Cliente', 
                    field: 'customerId', 
                    fieldType: FieldType.SEARCHBOX,
                    operation: 'GROUP',
                    config: {
                        isRemote: false,
                        source: 'CUSTOMER',
                        valueField: 'id',
                        showFields: ['fullName'],
                    }        
                },
                {
                    name: 'Fecha', 
                    field: 'txDate',
                    fieldType: FieldType.DATE,
                },
                {
                    name: 'Observation',
                    field: 'observation',
                    fieldType: FieldType.SIMPLE_TEXT,
                },
                {
                    name: 'Importe',
                    field: 'amount', 
                    fieldType: FieldType.MONEY, 
                },
                {
                    name: 'Saldo',
                    field: 'balance',
                    fieldType: FieldType.MONEY,
                    operation: 'PREV.balance###PLUS###CURRENT.amount'
                }
            ]
        }
    ],
}
// END TRANSACTION

// START MENU

const mainMenu: IActionMenu[] = [
    {
        title: 'Clientes',
        actionType: MENU_ACTION_TYPE.SUBMENU,
        subMenu: [
            {
                title: 'Buscar Cliente',
                actionType: MENU_ACTION_TYPE.NEW_TAB,
                newTab: 'BROWSE.CUSTOMER',
            },
            {
                title: 'Nuevo Cliente',
                actionType: MENU_ACTION_TYPE.NEW_TAB,
                newTab: 'EDIT.CUSTOMER',
            },
        ]
    },
    {
        title: 'Ventas',
        actionType: MENU_ACTION_TYPE.SUBMENU,
        subMenu: [
            {
                title: 'Buscar Ventas',
                actionType: MENU_ACTION_TYPE.NEW_TAB,
                newTab: 'BROWSE.SALE',
            },
            {
                title: 'Nueva Venta',
                actionType: MENU_ACTION_TYPE.NEW_TAB,
                newTab: 'EDIT.SALE',
            },
            {
                title: 'Reporte - Saldos',
                actionType: MENU_ACTION_TYPE.NEW_TAB,
                newTab: 'REPORT.SALE_BALANCES'
            },
            {
                title: 'Resumen de Cuentas',
                actionType: MENU_ACTION_TYPE.NEW_TAB,
                newTab: 'REPORT.SALE_SUMMARY'
            },
        ]
    },
]
// END MENU

const CTAS_APP_DEFINITION: AppDefinition = {
    appId: 'CTAS',
    appUrl: 'accounts',
    screens: [
        editSale,
        browseSale,
        browseCustomer,
        editCustomer,
        reportSaleBalances,
        reportSaleSummary,
    ],
    menu: mainMenu,
    functions: [],
    filters,
    transformers,
    initialState,
}

export default CTAS_APP_DEFINITION