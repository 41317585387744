import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiDelCall, apiGetCall, apiPostCall } from '../../app/data/api.data';
import { RootState } from '../store';
import { NotificationType, addNotification } from './notifications.slice';
import { allDefinitions } from '../../app/data/allDefinitions';
import { AppDefinition } from './app.slice';

export interface DataSliceState {
    fullList: any[],
    loading: boolean
}

export interface IFetchParams{
    dataType: string
}

export const fetchData = createAsyncThunk(
    'data/fetchData', 
    async (params: IFetchParams, thunkApi) => {
        const { dataType } = params
        const currState = thunkApi.getState() as RootState
        const appId = currState.data.appId
        
        const url = `basic/${appId.toLowerCase()}/getAllActive/${dataType.toLowerCase()}`
        try {
            const response = await (
                await apiGetCall(appId, url)
            )?.data

            return {
                dataType,
                response
            }
        } catch (error) {
            console.error(error)
            thunkApi.dispatch(addNotification({
                title: 'Error mientras se buscaban los datos',
                type: NotificationType.ERROR
            }))
            return thunkApi.rejectWithValue(error)
        }
    }
)

export const saveData = createAsyncThunk(
    'data/saveData',
    async (params: any, thunkApi) => {
        const { dataType, values } = params
        const currState = thunkApi.getState() as RootState
        const appId = currState.data.appId

        const url = `basic/${appId.toLowerCase()}/upsert/${dataType.toLowerCase()}`
        if (!dataType) {
            return thunkApi.rejectWithValue('invalid action parameter')
        }

        try {
            await apiPostCall(appId, url, values)
            thunkApi.dispatch(fetchData({dataType}))
            thunkApi.dispatch(addNotification({
                title: 'Los nuevos datos han sido guardados correctamente',
                type: NotificationType.SUCCESS
            }))
        } catch (error) {
            console.error("Axios saveData Error", error)
            thunkApi.dispatch(addNotification({
                title: 'Error mientras se guardaban los datos',
                type: NotificationType.ERROR
            }))

            return thunkApi.rejectWithValue(error)
        }
    }
)

export const deleteData = createAsyncThunk(
    'data/deleteData',
    async (params: {dataType: string, id: number}, thunkApi) => {
        const { dataType, id } = params
        const currState = thunkApi.getState() as RootState
        const appId = currState.data.appId
        
        if (!dataType || id < 1) {
            return thunkApi.rejectWithValue('invalid action parameters')
        }
        const url = `basic/${appId.toLowerCase()}/deleteById/${dataType.toLowerCase()}/${id}`

        try {
            await apiDelCall(appId, url)
            thunkApi.dispatch(fetchData({dataType}))
            thunkApi.dispatch(addNotification({
                title: 'Los datos han sido eliminados correctamente',
                type: NotificationType.SUCCESS
            }))
        } catch (error) {
            console.error("Axios deleteData Error", error)
            thunkApi.dispatch(addNotification({
                title: 'Error mientras se eliminaban los datos',
                type: NotificationType.ERROR
            }))
            return thunkApi.rejectWithValue(error)
        }
    }
)

const defaultIntialState: any = {}

export const dataSlice = createSlice({
    name: 'data',
    initialState: {
        appId: '',
        data: defaultIntialState,
        loading: false
    },
    reducers: {
        setAppId: (state, payload) => {
            const definition = allDefinitions.find((d: AppDefinition) => d.appId === payload.payload)
            if (definition) {
                state.appId = payload.payload
                state.data = definition.initialState
            } else {
                // REDIRECT TO A "NO DEF" PAGE
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchData.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchData.fulfilled, (state, action) => {
            const { dataType, response } = action.payload
            state.data[dataType.toLowerCase()].fullList = response

            state.loading = false
        })
        builder.addCase(fetchData.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(saveData.pending, (state) => {
            state.loading = true
        })
        builder.addCase(saveData.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(saveData.rejected, (state) => {
            state.loading = false
        })
        builder.addCase(deleteData.pending, (state) => {
            state.loading = true
        })
        builder.addCase(deleteData.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteData.rejected, (state) => {
            state.loading = false
        })
    }
})

export const { setAppId } = dataSlice.actions

export default dataSlice.reducer