import { useState } from 'react'
import { FieldConfig } from '../../models/tabs.model'
import { ClearButton } from './ClearButton.component'


const isValidNumber = (number: string, separator: string) => {
    const parts = number.split(separator)
    if (parts.length > 2) {
        return false
    }
    if (!(parts.every(p => p === '' || p.match(/[0-9]/g)?.join('') === p ))) {
        return false
    }
    return true
}
interface MoneyLabelProps {
    value: number, 
    decimals?: number,
}
export const MoneyLabel = ({value, decimals}: MoneyLabelProps) => {

    return (
        <span>
            {value === undefined && ' -- '}
            {value !== undefined && `$ ${(typeof value === 'number' ? value : parseFloat(value)).toFixed(decimals || 2)}`}
        </span>
    )
}

interface MoneyInputProps {
    value: any,
    config?: FieldConfig,
    onChangeHandler: Function,
    field: string,
    showClearButton?: boolean,
}
export const MoneyInput = ({value, config, onChangeHandler, field, showClearButton}: MoneyInputProps) => {
    const [ numericValue, setNumericValue  ] = useState<number>(value || parseFloat(config?.defaultValue || '0'))

    const isValid = (newValue: string) => {
        if (!isValidNumber(newValue, config?.separator || '.')) {
            return false
        }

        const decimals = config?.decimals || 0

        if (decimals == 0 && newValue.includes(config?.separator || '.')) {
            return false
        }
        if (decimals > 0 && newValue.includes(config?.separator || '.') && newValue.split(config?.separator || '.')[1].length > decimals) {
            return false
        }
        if (config?.max && parseFloat(newValue) > config?.max) {
            return false
        }

        return true
    }

    const handleOnChange = (event: any) => {
        if (event.target.value === '' || event.target.value === undefined) {
            event.target.value = 0
        }
        if (isValid(event.target.value)) {
            setNumericValue(parseFloat(event.target.value))
            onChangeHandler(field, parseFloat(event.target.value))
        } else {
            event.target.value = numericValue
        }
    }

    return (
        <>
            <input 
                style={{width: `${(15 * (config?.max?.toString().length || 8) + 15* (config?.decimals || 0))}px`}}
                value={numericValue}
                onChange={handleOnChange}
            />
            {showClearButton && 
                <ClearButton action={() => handleOnChange({target: {value: config?.defaultValue || '0'}})}/>
            }
        </>
    )

}