import { Title } from './tabs.model'

export enum StepType {
    ORDER = 'ORDER',
    FILTER = 'FILTER',
    CALC = 'CALC',
    LINES = 'LINES',
    SUM = 'SUM',
    UNION = 'UNION'
}

export type Step = {
    stepSource?: string,
    stepDestination: string,
} & (
        {
            stepType: 'FILTER',
            stepFilters?: string[], 
        }
    |
        {
            stepType: 'SUM',
            stepTitles: Title[]
        }
    |
        {
            stepType: 'UNION',
            stepSources: string[],
        }
    |
        {
            stepType: 'CALC',
            stepTitles: Title[]
        }
)

