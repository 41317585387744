

interface ClearButtonProps {
    action: Function,
}
export const ClearButton = ({action}: ClearButtonProps) => {

    return (
        <span 
            style={{color: 'red', fontWeight:'bolder'}}
            onClick={() => action()}
        >
            X
        </span>
    )
}